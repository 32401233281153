import { useEffect } from "react";

import Meta from "../../organisms/meta/meta";
import { Events, trackWithPromise } from "../../utils/analytics";
import { getCurrentURLSearchParams } from "../../utils/browser-features";
import { rollbar } from "../../utils/rollbar";

const TrustpilotRedirectPage = (): JSX.Element => {
  useEffect(() => {
    const urlSearchParams = getCurrentURLSearchParams();
    const source = urlSearchParams?.get("source") || "unknown";

    const trackEvent = async (retries = 3) => {
      try {
        await trackWithPromise(Events.REDIRECT_TO_TRUSTPILOT, { source });
      } catch (error) {
        if (retries > 0) {
          await trackEvent(retries - 1);
        } else {
          rollbar.warn("Error tracking redirect to trustpilot:", error, {
            fingerprint: "tracking-redirect-error",
            context: "trustpilot-redirect",
          });
        }
      }
    };

    const executeTrackingAndRedirect = async () => {
      await trackEvent();
      window.location.href = "https://es.trustpilot.com/review/barkibu.com";
    };

    void executeTrackingAndRedirect();
  }, []);

  return (
    <Meta
      title={"Redirecting to Trustpilot"}
      lang={"es"}
      noIndex={true}
      slug={undefined}
      description={"Redirecting to Trustpilot"}
      bodyClass={"body-redirect"}
      disableTranslations={true}
    />
  );
};

export default TrustpilotRedirectPage;
